import * as React from "react"
import tw from "twin.macro";

import { H1 } from "../components/Heading";
import { Layout } from "../components/Layout";

const Center = tw.div`
  text-center
`;

const ParagraphWithMargin = tw.p`
  mt-16
`;

const FormSuccessPage = () => {
  return (
    <Layout>
      <Center>
        <H1>Potvrdili jste účast na&nbsp;MeetUpu</H1>
        <p>Během jednoho dne vám zašleme e-mail s potvrzením o&nbsp;registraci.</p>
        <ParagraphWithMargin>Těšíme se&nbsp;na&nbsp;vás!</ParagraphWithMargin>
      </Center>
    </Layout>
  )
};

export default FormSuccessPage
